import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "effects"
    }}>{`Effects`}</h1>
    <blockquote>
      <h5 parentName="blockquote" {...{
        "id": "不熟悉-observablesrxjs-v6"
      }}>{`不熟悉 Observables/RxJS v6?`}</h5>
      <p parentName="blockquote">{`使用 `}<inlineCode parentName="p">{`Sigi`}</inlineCode>{` 需要你对 Observables/RxJS v6 有一定的了解。如果你是第一次接触 `}<inlineCode parentName="p">{`RxJS v6`}</inlineCode>{`，可以先到 `}<a parentName="p" {...{
          "href": "http://reactivex.io/rxjs/"
        }}>{`http://reactivex.io/rxjs/`}</a>{` 了解一下。`}</p>
    </blockquote>
    <p><strong parentName="p">{`Effect`}</strong>{` 是 `}<inlineCode parentName="p">{`Sigi`}</inlineCode>{` 的核心原语。它是 `}<inlineCode parentName="p">{`EffectModule`}</inlineCode>{` 类上的一个方法，接收一个 `}<inlineCode parentName="p">{`Payload`}</inlineCode>{` 流，返回一个 `}<inlineCode parentName="p">{`Action`}</inlineCode>{` 流。`}<strong parentName="p">{`流入 Payload，流出 Action`}</strong></p>
    <p>{`它的函数签名是这样:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`<Payload>(action$: Observable<Payload>): Observable<Action>;
`}</code></pre>
    <p>{`虽然通常你是接收到 `}<inlineCode parentName="p">{`Payload`}</inlineCode>{` 然后产生 `}<inlineCode parentName="p">{`Action`}</inlineCode>{`, 但在 `}<strong parentName="p">{`Effect`}</strong>{` 中并不需要时时刻刻遵守这一点。在 `}<strong parentName="p">{`Effect`}</strong>{` 内部，你可以用 `}<inlineCode parentName="p">{`RxJS`}</inlineCode>{` 做任何事情，只要你保证最后流出的是 `}<inlineCode parentName="p">{`Action`}</inlineCode>{` 就行了。`}</p>
    <p>{`这些在 `}<strong parentName="p">{`Effect`}</strong>{` 中流出的 `}<inlineCode parentName="p">{`Action`}</inlineCode>{` 会被立刻 `}<inlineCode parentName="p">{`Dispatch`}</inlineCode>{` 给它们的 `}<strong parentName="p">{`Module`}</strong>{`。`}</p>
    <p>{`如果在 `}<strong parentName="p">{`Effect`}</strong>{` 中流出的 `}<inlineCode parentName="p">{`Action`}</inlineCode>{` 与 `}<strong parentName="p">{`Effect`}</strong>{` 自身是对应的，就`}<strong parentName="p"><em parentName="strong">{`可能`}</em></strong>{`会出现无限循环的情况:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// 不要这样做
something(payload$: Observable<void>) {
  return payload$.map(() => this.getActions().something())
}
`}</code></pre>
    <h2 {...{
      "id": "在-effect-中访问-appstate"
    }}>{`在 `}<strong parentName="h2">{`Effect`}</strong>{` 中访问 `}<strong parentName="h2">{`AppState`}</strong></h2>
    <p>{`在 `}<inlineCode parentName="p">{`EffectModule`}</inlineCode>{` 实例中，可以通过 `}<inlineCode parentName="p">{`state$`}</inlineCode>{` 属性获取最新的状态，这个 `}<inlineCode parentName="p">{`state$`}</inlineCode>{` 是一个永远拥有最新状态值的 `}<inlineCode parentName="p">{`Observable`}</inlineCode>{`。
我们没有提供任何直接在 `}<strong parentName="p">{`Effect`}</strong>{` 中访问`}<strong parentName="p">{`状态`}</strong>{`的方法，因为直接访问而不是通过响应式的方法访问状态在一些场景下会引起非常不易察觉的 bug。思考一下下面这个例子:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`@Module('B')
class ModuleB extends EffectModule<BState> {
  defaultState = {}

  constructor(private readonly httpClient: HttpClient) {
    super()
  }

  @Effect()
  addAndSync(payload$: Observable<number>) {
    return payload$.pipe(
      mergeMap((payload) => {
        const state = this.getState()
        return this.httpClient.get(\`/api/something\`, {
          body: {
            query: payload
          }
        }).pipe(
          map((response) => {
            if (state.expireIn < Date.now()) { // state here is staled
              return this.getActions().setResponse(response.body)
            } else {
              return this.getActions().setExpired(response.body)
            }
          }),
          catchError(...),
          startWith(this.getActions().setLoading(true)),
          endWith(this.getActions().setLoading(false)),
        )
      })
    )
  }
}
`}</code></pre>
    <p>{`如果我们在第一个 `}<inlineCode parentName="p">{`mergeMap`}</inlineCode>{` 中使用 `}<inlineCode parentName="p">{`getState`}</inlineCode>{` 直接访问状态并且在后面的 `}<inlineCode parentName="p">{`map`}</inlineCode>{` 中复用这个状态，那在这个状态真正被使用的时候可能已经过期了(不是最新的值)。虽然我们可以很简单的通过在 `}<inlineCode parentName="p">{`map`}</inlineCode>{` 操作符中再次获取状态来修复这个 bug，但是这种类似的问题真的 `}<strong parentName="p"><em parentName="strong">{`非常难 debug`}</em></strong>{`。`}</p>
    <p>{`所以，永远通过 `}<inlineCode parentName="p">{`响应式`}</inlineCode>{` 的方法访问状态是更好的实践方式:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`@Module('B')
class ModuleB extends EffectModule<BState> {
  defaultState = {}

  constructor(private readonly httpClient: HttpClient) {
    super()
  }

  @Effect()
  addAndSync(payload$: Observable<number>) {
    return payload$.pipe(
      mergeMap((payload) => {
        return this.httpClient.get(\`/api/something\`, {
          body: {
            query: payload
          }
        }).pipe(
          withLatestFrom(this.state$),
          map(([response, state]) => {
            if (state.expireIn < Date.now()) { // always latest state here
              return this.getActions().setResponse(response.body)
            } else {
              return this.getActions().setExpired(response.body)
            }
          }),
          catchError(...),
          startWith(this.getActions().setLoading(true)),
          endWith(this.getActions().setLoading(false)),
        )
      })
    )
  }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      